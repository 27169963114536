import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles(theme => ({
  dataSource: {
    margin: theme.spacing(1)
  }
}));

interface DataSourceProps {
    dataSource: string,
    dataPreview: string,
    handleChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleClearValue: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const DataSource : React.FC<DataSourceProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <FormControl className={classes.dataSource} fullWidth={true}>
        <InputLabel htmlFor="input-with-icon-adornment">
          Regular Expression
        </InputLabel>

        <Input
          id="input-with-icon-adornment"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={props.handleClearValue}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={props.handleChangeValue}
          value={props.dataSource}
        />
        <FormHelperText id="component-helper-text">
          {props.dataPreview}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default DataSource;
