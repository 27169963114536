import * as React from "react";

// import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// const useStyles = makeStyles(theme => ({
//   button: {
//     margin: theme.spacing(1)
//   }
// }));

interface ResultFieldProps {
  result: string,
}

const ResultField : React.FC<ResultFieldProps> = (props) => {
  // const classes = useStyles();

  return (
    <TextField
      id="outlined-multiline-static"
      label="Result"
      multiline
      rows="4"
      variant="filled"
      fullWidth={true}
      InputProps={{
        readOnly: true
      }}
      InputLabelProps={{
        shrink: true
      }}
      value={props.result}
      inputProps={{ min: 1, max: 1000 }}
    />
  );
};

export default ResultField;
