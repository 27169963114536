import actionCreatorFactory from "typescript-fsa";
const actionCreator = actionCreatorFactory();

export const testDataActions =
{
  sourceText: actionCreator<{ sourceText: string }>("SOURCE_TEXT"),
  quantity: actionCreator<{ quantity: number }>("QUANTITY"),
  sourcePreview: actionCreator("SOURCE_PREVIEW_UPDATE"),
  testDataGenerate: actionCreator("TEST_DATA_GENERATE"),
  copyText: actionCreator("COPY_TEXT"),
  hideCopied: actionCreator("HIDE_COPIED_TEXT"),
};
