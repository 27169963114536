import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

interface DownloadButtonProps {
    handleDownload: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const DownloadButton : React.FC<DownloadButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Button variant="contained" className={classes.button} onClick={props.handleDownload}>
      Download
    </Button>
  );
};

export default DownloadButton;
