import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

interface GenerateButtonProps {
    handleGenerate: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const GenerateButton : React.FC<GenerateButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={props.handleGenerate}
    >
      Generate
    </Button>
  );
};

export default GenerateButton;
