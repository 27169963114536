import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  quantity: {
    margin: theme.spacing(1)
  }
}));

interface DataQuantityProps {
    quantity: number,
    handleChangeQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const DataQuantity : React.FC<DataQuantityProps> = (props) => {
  const classes = useStyles();

  return (
    <TextField
      error={false ? true : false}
      id="standard-number"
      label="Quantity"
      type="number"
      className={classes.quantity}
      InputLabelProps={{
        shrink: true
      }}
      margin="normal"
      value={props.quantity}
      onChange={props.handleChangeQuantity}
    />
  );
};

export default DataQuantity;
