import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
    marginTop: theme.spacing(1)
  },
  subTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(1)
  }
}));

const Title = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.title}>
        Random String Generator
      </Typography>
      <Typography variant="subtitle2" gutterBottom className={classes.subTitle}>
        generate random strings from regular expression
      </Typography>
    </React.Fragment>
  );
};

export default Title;
