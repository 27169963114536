import React from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import mainColor from "@material-ui/core/colors/blueGrey";
import CssBaseline from "@material-ui/core/CssBaseline";

import TestDataGenerator from "./containers/TestDataGenerator";

import ReactGA from "react-ga";

const theme = createMuiTheme({
  palette: {
    primary: mainColor,
    type: "dark"
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(",")
  }
});

const App = () => {
  React.useEffect(() => {
    ReactGA.initialize("UA-152965011-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TestDataGenerator />
      </ThemeProvider>
    </div>
  );
}

export default App;
