import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

interface CopyButtonProps {
    handleCopy: (event: React.MouseEvent<HTMLButtonElement>) => void
    showCopied: boolean,
}

const CopyButton : React.FC<CopyButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Copied"
      open={props.showCopied}
    >
      <Button
        variant="contained"
        className={classes.button}
        onClick={props.handleCopy}
      >
        Copy
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
