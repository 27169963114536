import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

const useStyles = makeStyles(theme => ({
  socials: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
}));

const SocialButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.socials}>
      <FacebookShareButton url="https://testdata.gnrt.app" className="mr-2">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url="https://testdata.gnrt.app"
        title="Random String Generator using Regular Expression"
        className="mr-2"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
};

export default SocialButton;
