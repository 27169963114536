import RandExp from "randexp";

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { testDataActions } from "../actions";

export interface TestDataGeneratorState {
  sourceText: string;
  quantity: number;
  sourcePreview: string;
  testData: string;
  showCopied: boolean;
}

const initialState: TestDataGeneratorState = {
  sourceText: "[a-zA-Z0-9]{2,8}",
  quantity: 10,
  sourcePreview: "",
  testData: "",
  showCopied: false,
};

const rootReducer = reducerWithInitialState(initialState)
  .case(testDataActions.sourceText, (state, sourceText) => {
    return Object.assign({}, state, sourceText);
  })
  .case(testDataActions.quantity, (state, quantity) => {
    return Object.assign({}, state, quantity);
  })
  .case(testDataActions.sourcePreview, (state, sourcePreview) => {
    let preview = "";
    try {
      const reg = new RegExp(state.sourceText);

      const randexp: RandExp = new RandExp(reg);
      preview = "preview: " + randexp.gen();
    } catch (e) {
      preview = "invalid regular expression.";
    }
    return Object.assign({}, state, { sourcePreview: preview });
  })
  .case(testDataActions.testDataGenerate, state => {
    const quantity = state.quantity;
    let testData = "";
    try {
      let testDataArray = [];
      const reg = new RegExp(state.sourceText);
      const randexp: RandExp = new RandExp(reg);

      for (let i = 0; i < quantity; i++) {
        testDataArray.push(randexp.gen());
      }

      testData = testDataArray.join("\n");
    } catch (e) {
      testData = "";
    }
    return Object.assign({}, state, { testData: testData });
  })
  .case(testDataActions.copyText, state => {
    return Object.assign({}, state, { showCopied: true });
  })
  .case(testDataActions.hideCopied, state => {
    return Object.assign({}, state, { showCopied: false });
  });

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;;
