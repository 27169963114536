import { createStore } from "redux";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

// const persistConfig = {
//   key: "randomStringGenerator",
//   storage,
//   blacklist: ["random"]
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(rootReducer);

// export const persistor = persistStore(store);
export default store;
