// import React, { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDebounce } from "use-debounce";

import { testDataActions } from "../actions";
import { RootState} from "../reducers"

import Title from "../components/Title";
import DataSource from "../components/DataSource";
import DataQuantity from "../components/DataQuantity"
import GenerateButton from "../components/GenerateButton";
import ResultField from "../components/ResultField";
import DownloadButton from "../components/DownloadButton"
import CopyButton from "../components/CopyButton";
import SocialButton from "../components/SocialButton"

import Grid from "@material-ui/core/Grid";

import ReactGA from "react-ga";
import copy from "clipboard-copy";
import { saveAs } from "file-saver";
import Moment from "moment";

const TestDataGenerator = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(testDataActions.testDataGenerate());
  }, [dispatch]);

  const sourceTextFilter = (state : RootState) : string => state.sourceText;
  const sourceText = useSelector(sourceTextFilter);

  const sourcePreviewFilter = (state: RootState): string => state.sourcePreview;
  const sourcePreview = useSelector(sourcePreviewFilter);

  const quantityFilter = (state: RootState): number => state.quantity;
  const quantity = useSelector(quantityFilter);

  const testDataFilter = (state: RootState): string => state.testData;
  const testData = useSelector(testDataFilter);

  const showCopiedSelector = (state :RootState) : boolean => state.showCopied;
  const showCopied = useSelector(showCopiedSelector);

  const handleDataSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    dispatch(testDataActions.sourceText({ sourceText: newValue}));
  }

  const handleClearDataSource = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(testDataActions.sourceText({ sourceText: ""}));
  }

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Math.max(1, Math.min(10000, Number(event.target.value)));
    dispatch(testDataActions.quantity({ quantity: newValue }));
  };

  const handleGenerateButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    ReactGA.event({
      category: "ButtonClick",
      action: "Generate",
      label: sourceText
    });

    dispatch(testDataActions.testDataGenerate());
  };

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    ReactGA.event({ category: "ButtonClick", action: "Copy" });

    copy(testData)
    dispatch(testDataActions.copyText());
    setTimeout(() => {
      dispatch(testDataActions.hideCopied());
    }, 1500);
  };

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    ReactGA.event({ category: "ButtonClick", action: "Download" });

    const currentTime = Moment().format("YYYYMMDDHHmmss");
    const blob = new Blob([testData], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, `testdata_${currentTime}.txt`);
  }

  const [debounceDataSource] = useDebounce(sourceText, 1000);

  React.useEffect(() => {
    dispatch(
      testDataActions.sourcePreview()
    );
  }, [debounceDataSource]);

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <Title />
        <SocialButton />
        <DataSource
          dataSource={sourceText}
          dataPreview={sourcePreview}
          handleChangeValue={handleDataSourceChange}
          handleClearValue={handleClearDataSource}
        />
        <Grid container justify="flex-start" alignItems="baseline">
          <DataQuantity
            quantity={quantity}
            handleChangeQuantity={handleChangeQuantity}
          />
          <GenerateButton handleGenerate={handleGenerateButton} />
        </Grid>
        <ResultField result={testData} />
        <Grid container justify="flex-start">
          <DownloadButton handleDownload={handleDownload} />
          <CopyButton handleCopy={handleCopy} showCopied={showCopied} />
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  );
};

export default TestDataGenerator;
